import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GuardianUserInfo';
import './GuardianAnchorArchivement';
var GuardianListChangeNotice = function GuardianListChangeNotice() {
  this.lRoomID = 0;
  this.iAllGuardianCount = 0;
  this.vUsers = new Taf.Vector(new Nimo.GuardianUserInfo());
  this.tArchivement = new Nimo.GuardianAnchorArchivement();
  this.tGuardianPosInfo = new Nimo.GuardianUserInfo();
  this.vActiveUsers = new Taf.Vector(new Nimo.GuardianUserInfo());
  this.vWeekTop = new Taf.Vector(new Nimo.GuardianUserInfo());
};
GuardianListChangeNotice.prototype._clone = function () {
  return new Nimo.GuardianListChangeNotice();
};
GuardianListChangeNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianListChangeNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianListChangeNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomID);
  os.writeInt32(1, this.iAllGuardianCount);
  os.writeVector(2, this.vUsers);
  os.writeStruct(3, this.tArchivement);
  os.writeStruct(4, this.tGuardianPosInfo);
  os.writeVector(5, this.vActiveUsers);
  os.writeVector(6, this.vWeekTop);
};
GuardianListChangeNotice.prototype.readFrom = function (is) {
  this.lRoomID = is.readInt64(0, false, this.lRoomID);
  this.iAllGuardianCount = is.readInt32(1, false, this.iAllGuardianCount);
  this.vUsers = is.readVector(2, false, this.vUsers);
  this.tArchivement = is.readStruct(3, false, this.tArchivement);
  this.tGuardianPosInfo = is.readStruct(4, false, this.tGuardianPosInfo);
  this.vActiveUsers = is.readVector(5, false, this.vActiveUsers);
  this.vWeekTop = is.readVector(6, false, this.vWeekTop);
};
Nimo.GuardianListChangeNotice = GuardianListChangeNotice;
export default GuardianListChangeNotice;