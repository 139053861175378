import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GuardianInfo';
var GuardianUserInfo = function GuardianUserInfo() {
  this.iIndex = 0;
  this.lUID = 0;
  this.tGuardianInfo = new Nimo.GuardianInfo();
  this.sNickName = "";
  this.sAvatarUrl = "";
  this.sAnchorNickName = "";
  this.sAnchorAvatarUrl = "";
  this.sBadgeIcon = "";
  this.sBadgeName = "";
  this.sLeftBadgeIcon = "";
  this.sLeftBadgeIconV2 = "";
  this.iFanLevel = 0;
  this.sDynamicAvatarBoxUrl = "";
  this.lSevenDayPoints = 0;
  this.iLiveStatus = 0;
  this.lRoomID = 0;
  this.iFansGroupEnable = 0;
  this.sGuardianPosFrameUrl = "";
  this.sKnightPosFrameUrl = "";
};
GuardianUserInfo.prototype._clone = function () {
  return new Nimo.GuardianUserInfo();
};
GuardianUserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianUserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianUserInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iIndex);
  os.writeInt64(1, this.lUID);
  os.writeStruct(2, this.tGuardianInfo);
  os.writeString(3, this.sNickName);
  os.writeString(4, this.sAvatarUrl);
  os.writeString(5, this.sAnchorNickName);
  os.writeString(6, this.sAnchorAvatarUrl);
  os.writeString(7, this.sBadgeIcon);
  os.writeString(8, this.sBadgeName);
  os.writeString(9, this.sLeftBadgeIcon);
  os.writeString(10, this.sLeftBadgeIconV2);
  os.writeInt32(11, this.iFanLevel);
  os.writeString(12, this.sDynamicAvatarBoxUrl);
  os.writeInt64(13, this.lSevenDayPoints);
  os.writeInt32(14, this.iLiveStatus);
  os.writeInt64(15, this.lRoomID);
  os.writeInt32(16, this.iFansGroupEnable);
  os.writeString(17, this.sGuardianPosFrameUrl);
  os.writeString(18, this.sKnightPosFrameUrl);
};
GuardianUserInfo.prototype.readFrom = function (is) {
  this.iIndex = is.readInt32(0, false, this.iIndex);
  this.lUID = is.readInt64(1, false, this.lUID);
  this.tGuardianInfo = is.readStruct(2, false, this.tGuardianInfo);
  this.sNickName = is.readString(3, false, this.sNickName);
  this.sAvatarUrl = is.readString(4, false, this.sAvatarUrl);
  this.sAnchorNickName = is.readString(5, false, this.sAnchorNickName);
  this.sAnchorAvatarUrl = is.readString(6, false, this.sAnchorAvatarUrl);
  this.sBadgeIcon = is.readString(7, false, this.sBadgeIcon);
  this.sBadgeName = is.readString(8, false, this.sBadgeName);
  this.sLeftBadgeIcon = is.readString(9, false, this.sLeftBadgeIcon);
  this.sLeftBadgeIconV2 = is.readString(10, false, this.sLeftBadgeIconV2);
  this.iFanLevel = is.readInt32(11, false, this.iFanLevel);
  this.sDynamicAvatarBoxUrl = is.readString(12, false, this.sDynamicAvatarBoxUrl);
  this.lSevenDayPoints = is.readInt64(13, false, this.lSevenDayPoints);
  this.iLiveStatus = is.readInt32(14, false, this.iLiveStatus);
  this.lRoomID = is.readInt64(15, false, this.lRoomID);
  this.iFansGroupEnable = is.readInt32(16, false, this.iFansGroupEnable);
  this.sGuardianPosFrameUrl = is.readString(17, false, this.sGuardianPosFrameUrl);
  this.sKnightPosFrameUrl = is.readString(18, false, this.sKnightPosFrameUrl);
};
Nimo.GuardianUserInfo = GuardianUserInfo;
export default GuardianUserInfo;