import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var UserBlockNotify = function UserBlockNotify() {
  this.lAUid = 0;
  this.lBUid = 0;
  this.iBlockStatus = 0;
};
UserBlockNotify.prototype._clone = function () {
  return new Nimo.UserBlockNotify();
};
UserBlockNotify.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserBlockNotify.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserBlockNotify.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAUid);
  os.writeInt64(1, this.lBUid);
  os.writeInt32(2, this.iBlockStatus);
};
UserBlockNotify.prototype.readFrom = function (is) {
  this.lAUid = is.readInt64(0, false, this.lAUid);
  this.lBUid = is.readInt64(1, false, this.lBUid);
  this.iBlockStatus = is.readInt32(2, false, this.iBlockStatus);
};
Nimo.UserBlockNotify = UserBlockNotify;
export default UserBlockNotify;